import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField, Button } from '@mui/material';
import { ethers } from 'ethers';
import { useWallet } from '../context/WalletContext';
import Layout from './Layout';
import { abi } from './abi';
import { uploadToPinata } from '../utils/pinata';
import { optimizeImage } from '../utils/imageOptimizer';

const TokenCreate = () => {
  const navigate = useNavigate();
  const { account, signer } = useWallet();
  const [name, setName] = useState('');
  const [ticker, setTicker] = useState('');
  const [maxTxPercent, setMaxTxPercent] = useState('1');
  const [image, setImage] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadingStatus, setUploadingStatus] = useState('Create Token');
  const [supply, setSupply] = useState('100000000');
  const [imageError, setImageError] = useState('');
  const [previewUrl, setPreviewUrl] = useState(null);

  const handleImageChange = async (e) => {
    const file = e.target.files?.[0];
    if (!file) return;

    try {
      setImageError('');
      
      // Check file type
      const validTypes = ['image/gif', 'image/jpeg', 'image/png'];
      if (!validTypes.includes(file.type)) {
        setImageError('Please upload a GIF, JPEG, or PNG file');
        return;
      }

      // Only optimize if not a GIF
      let finalImage = file;
      if (file.type !== 'image/gif') {
        finalImage = await optimizeImage(file);
      }
      
      setImage(finalImage);
      
      // Create and set preview URL
      const preview = URL.createObjectURL(finalImage);
      setPreviewUrl(preview);
      
    } catch (error) {
      setImageError(error.message);
      setImage(null);
      setPreviewUrl(null);
    }
  };

  // Cleanup preview URL when component unmounts
  useEffect(() => {
    return () => {
      if (previewUrl) {
        URL.revokeObjectURL(previewUrl);
      }
    };
  }, [previewUrl]);

  const handleMaxTxChange = (e) => {
    const value = e.target.value;
    if (value === '' || /^\d*\.?\d*$/.test(value)) {
      const numValue = parseFloat(value);
      if (value === '' || (numValue >= 0.1 && numValue <= 10)) {
        setMaxTxPercent(value);
      }
    }
  };

  const handleSupplyChange = (e) => {
    const value = e.target.value;
    if (value === '' || /^\d+$/.test(value)) {
      const numValue = parseInt(value, 10);
      if (value === '' || (numValue >= 100 && numValue <= 100000000000)) {
        setSupply(value);
      }
    }
  };

  const handleCreate = async () => {
    if (!signer || !account) {
      alert('Please connect your wallet first');
      return;
    }

    if (!image) {
      alert('Please select an image');
      return;
    }

    try {
      setUploading(true);
      setUploadingStatus('Uploading image to IPFS...');

      // Upload image to Pinata
      const imageUrl = await uploadToPinata(image);
      
      setUploadingStatus('Creating token...');

      const contract = new ethers.Contract(
        process.env.REACT_APP_CONTRACT_ADDRESS,
        abi,
        signer
      );

      const tx = await contract.createToken(
        name,
        ticker,
        imageUrl,
        supply,
        Math.floor(parseFloat(maxTxPercent)),
        { value: ethers.utils.parseEther("0.01") }
      );

      setUploadingStatus('Waiting for confirmation...');
      await tx.wait();

      navigate('/');
    } catch (error) {
      console.error('Error creating token:', error);
      alert('Error creating token. See console for details.');
    } finally {
      setUploading(false);
      setUploadingStatus('Create Token');
    }
  };

  return (
    <Layout>
      <div style={{ 
        width: '100%',
        maxWidth: '600px', 
        margin: '0 auto', 
        padding: window.innerWidth <= 768 ? '10px' : '24px'
      }}>
        <h2 style={{ color: 'white', marginBottom: '16px' }}>Token Creation</h2>
        <h3 style={{ color: '#a78bfa', marginBottom: '24px' }}>Creation fee: 0.01 TARA</h3>
        <TextField
          label="Token Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          fullWidth
          margin="normal"
          sx={{
            '& .MuiInputLabel-root': { color: 'rgba(255, 255, 255, 0.7)' },
            '& .MuiInputLabel-root.Mui-focused': { color: '#9333ea' },
            '& .MuiOutlinedInput-root': {
              '& fieldset': { borderColor: 'rgba(255, 255, 255, 0.23)' },
              '&:hover fieldset': { borderColor: 'rgba(255, 255, 255, 0.4)' },
              '&.Mui-focused fieldset': { borderColor: '#9333ea' },
            },
            '& .MuiOutlinedInput-input': { color: 'white' },
          }}
        />
        <TextField
          label="Token Symbol"
          value={ticker}
          onChange={(e) => setTicker(e.target.value)}
          fullWidth
          margin="normal"
          sx={{
            '& .MuiInputLabel-root': { color: 'rgba(255, 255, 255, 0.7)' },
            '& .MuiInputLabel-root.Mui-focused': { color: '#9333ea' },
            '& .MuiOutlinedInput-root': {
              '& fieldset': { borderColor: 'rgba(255, 255, 255, 0.23)' },
              '&:hover fieldset': { borderColor: 'rgba(255, 255, 255, 0.4)' },
              '&.Mui-focused fieldset': { borderColor: '#9333ea' },
            },
            '& .MuiOutlinedInput-input': { color: 'white' },
          }}
        />
        <TextField
          label="Max Transaction Limit (%)"
          value={maxTxPercent}
          onChange={handleMaxTxChange}
          type="number"
          inputProps={{
            min: 0.1,
            max: 10,
            step: 0.1
          }}
          helperText="Enter a value between 0.1% and 10%"
          fullWidth
          margin="normal"
          sx={{
            '& .MuiInputLabel-root': { color: 'rgba(255, 255, 255, 0.7)' },
            '& .MuiInputLabel-root.Mui-focused': { color: '#9333ea' },
            '& .MuiOutlinedInput-root': {
              '& fieldset': { borderColor: 'rgba(255, 255, 255, 0.23)' },
              '&:hover fieldset': { borderColor: 'rgba(255, 255, 255, 0.4)' },
              '&.Mui-focused fieldset': { borderColor: '#9333ea' },
            },
            '& .MuiOutlinedInput-input': { color: 'white' },
            '& .MuiFormHelperText-root': { color: 'rgba(255, 255, 255, 0.6)' },
          }}
        />
        <TextField
          label="Total Token Supply"
          value={supply}
          onChange={handleSupplyChange}
          type="number"
          inputProps={{
            min: 100,
            max: 100000000000,
            step: 1
          }}
          helperText="Enter a value anywhere between 100 and 100 000 000 000"
          fullWidth
          margin="normal"
          sx={{
            '& .MuiInputLabel-root': { color: 'rgba(255, 255, 255, 0.7)' },
            '& .MuiInputLabel-root.Mui-focused': { color: '#9333ea' },
            '& .MuiOutlinedInput-root': {
              '& fieldset': { borderColor: 'rgba(255, 255, 255, 0.23)' },
              '&:hover fieldset': { borderColor: 'rgba(255, 255, 255, 0.4)' },
              '&.Mui-focused fieldset': { borderColor: '#9333ea' },
            },
            '& .MuiOutlinedInput-input': { color: 'white' },
            '& .MuiFormHelperText-root': { color: 'rgba(255, 255, 255, 0.6)' },
          }}
        />
        <div style={{ marginTop: '20px', marginBottom: '20px' }}>
          <input
            type="file"
            accept="image/gif,image/jpeg,image/png"
            onChange={handleImageChange}
            style={{ display: 'none' }}
            id="image-upload"
          />
          <label htmlFor="image-upload">
            <Button
              variant="outlined"
              component="span"
              fullWidth
              error={!!imageError}
              sx={{
                color: 'white',
                borderColor: 'rgba(255, 255, 255, 0.23)',
                '&:hover': {
                  borderColor: 'rgba(255, 255, 255, 0.4)',
                  backgroundColor: 'rgba(255, 255, 255, 0.05)'
                }
              }}
            >
              {image ? `Selected: ${image.name} (${(image.size / 1024 / 1024).toFixed(2)}MB)` : 'Upload Token Image'}
            </Button>
          </label>

          {/* Image Preview */}
          {previewUrl && (
            <div style={{ 
              marginTop: '16px',
              width: '100%',
              display: 'flex',
              justifyContent: 'center'
            }}>
              <img
                src={previewUrl}
                alt="Token preview"
                style={{
                  maxWidth: '200px',
                  maxHeight: '200px',
                  objectFit: 'contain',
                  borderRadius: '8px',
                  border: '2px solid rgba(147, 51, 234, 0.3)'
                }}
              />
            </div>
          )}

          {imageError && (
            <p style={{ color: '#ef4444', marginTop: '8px', fontSize: '14px' }}>
              {imageError}
            </p>
          )}
          {image && !imageError && (
            <p style={{ color: '#a78bfa', marginTop: '8px', fontSize: '14px' }}>
              {image.type === 'image/gif' ? 'GIF ready to upload' : 'Image optimized successfully'}
            </p>
          )}
        </div>
        <Button 
          variant="contained"
          onClick={handleCreate}
          disabled={uploading || !image}
          fullWidth
          sx={{
            backgroundColor: '#9333ea',
            '&:hover': {
              backgroundColor: '#7e22ce'
            },
            '&:disabled': {
              backgroundColor: 'rgba(147, 51, 234, 0.5)',
              color: 'rgba(255, 255, 255, 0.7)'
            }
          }}
        >
          {uploading ? uploadingStatus : 'Create Token'}
        </Button>
      </div>
    </Layout>
  );
};

export default TokenCreate;
