import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { collection, query, where, orderBy, limit, onSnapshot } from 'firebase/firestore';
import { db } from '../config/firebase';

const TokenChart = ({ tokenAddress }) => {
  const [candleData, setCandleData] = useState([]);
  const [timeframe, setTimeframe] = useState('5m');

  const options = {
    chart: {
      type: 'candlestick',
      height: 350,
      background: 'transparent',
      animations: {
        enabled: true,
      }
    },
    title: {
      text: 'Price Chart',
      align: 'left',
      style: {
        color: '#718096'
      }
    },
    xaxis: {
      type: 'datetime',
      labels: {
        style: {
          colors: '#718096'
        }
      }
    },
    yaxis: {
      tooltip: {
        enabled: true
      },
      labels: {
        formatter: function(val) {
          return (val * 1e9).toFixed(2) + ' nTARA';
        },
        style: {
          colors: '#718096'
        }
      },
      tickAmount: 10,
      floating: false,
      decimalsInFloat: 10,
    },
    plotOptions: {
      candlestick: {
        colors: {
          upward: '#10B981',
          downward: '#EF4444'
        },
        wick: {
          useFillColor: true,
        }
      }
    },
    tooltip: {
      y: {
        formatter: function(val) {
          return val.toFixed(12) + ' TARA';
        }
      }
    }
  };

  useEffect(() => {
    if (!tokenAddress) return;

    const q = query(
      collection(db, 'candles'),
      where('tokenAddress', '==', tokenAddress),
      where('timeframe', '==', timeframe),
      orderBy('timestamp', 'desc'),
      limit(100)
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const newData = snapshot.docs.map(doc => {
        const data = doc.data();
        return {
          x: data.timestamp.toDate(),
          y: [data.open, data.high, data.low, data.close].map(price => price * 1)
        };
      }).reverse();

      setCandleData(newData);
    });

    return () => unsubscribe();
  }, [tokenAddress, timeframe]);

  return (
    <div className="chart-container">
      <div className="timeframe-selector mb-4">
        {['5m', '15m', '1h', '4h', '1d'].map((tf) => (
          <button
            key={tf}
            onClick={() => setTimeframe(tf)}
            className={`px-3 py-1 rounded mr-2 ${
              timeframe === tf 
                ? 'bg-purple-600 text-white' 
                : 'bg-purple-100 text-purple-600'
            }`}
          >
            {tf}
          </button>
        ))}
      </div>
      
      <ReactApexChart
        options={options}
        series={[{ data: candleData }]}
        type="candlestick"
        height={350}
      />
    </div>
  );
};

export default TokenChart; 