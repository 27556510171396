import React, { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import Card from './Card';
import Layout from './Layout';
import { abi } from './abi';
import Lottie from 'lottie-react';
import tokenloadingAnimation from '../assets/LoadTokensAnim.json';
import { provider } from '../utils/provider';

const Home = () => {
  const [cards, setCards] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const fetchMemeTokens = async () => {
      try {
        const contract = new ethers.Contract(
          process.env.REACT_APP_CONTRACT_ADDRESS,
          abi,
          provider
        );
        
        try {
          const tokens = await contract.getAllTokens();
          setCards(
            tokens.map(token => ({
              name: token.name,
              symbol: token.symbol,
              imageUrl: token.imageUrl,
              tokenAddress: token.tokenAddress,
              totalSupply: ethers.utils.formatEther(token.totalSupply),
              exists: token.exists
            }))
          );
        } catch (error) {
          console.error('Error details:', error);
          throw error;
        }
      } catch (error) {
        console.error('Error fetching tokens:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchMemeTokens();
  }, []);

  const filteredCards = cards.filter(token => {
    const search = searchTerm.toLowerCase();
    return (
      token.name.toLowerCase().includes(search) ||
      token.symbol.toLowerCase().includes(search)
    );
  });

  return (
    <Layout>
      <div className="search-container">
        <input
          type="text"
          placeholder="Search by token name or symbol..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="search-input"
        />
        <div className="search-icon">🔍</div>
      </div>
      
      {loading ? (
        <div style={{ width: '300px', height: '300px' }}>
          <Lottie 
            animationData={tokenloadingAnimation} 
            loop={true}
            style={{ width: '100%', height: '100%' }}
          />
        </div>
      ) : (
        <div className="token-container">
          <div className="token-grid">
            {filteredCards.map((token) => (
              <Card key={token.tokenAddress} token={token} />
            ))}
          </div>
        </div>
      )}
    </Layout>
  );
};

export default Home;
