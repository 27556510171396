import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { ethers } from 'ethers';
import Layout from './Layout';
import { useWallet } from '../context/WalletContext';
import { abi } from './abi';
import { tokenAbi } from './tokenAbi';
import Lottie from 'lottie-react';
import tokenloadingAnimation from '../assets/LoadTokensAnim.json';
import Comments from './Comments';
import TokenChart from '../components/TokenChart';
import { provider } from '../utils/provider';

const FACTORY_ADDRESS = process.env.REACT_APP_CONTRACT_ADDRESS;

const TokenPage = () => {
  const { tokenAddress } = useParams();
  const [token, setToken] = useState(null);
  const [amount, setAmount] = useState('');
  const [loading, setLoading] = useState(true);
  const [transactionPending, setTransactionPending] = useState(false);
  const [userBalance, setUserBalance] = useState('0');
  const { signer, account } = useWallet();
  const [quoteData, setQuoteData] = useState({
    totalCost: ethers.BigNumber.from(0),
    isStale: false,
    isLoading: false
  });

  const getImageUrl = (ipfsUrl) => {
    if (!ipfsUrl) return null;
    return ipfsUrl.replace('ipfs://', 'https://gateway.pinata.cloud/ipfs/');
  };

  const fetchTokenDetails = useCallback(async () => {
    try {
      const factoryContract = new ethers.Contract(
        FACTORY_ADDRESS,
        abi,
        provider
      );
      
      const tokenInfo = await factoryContract.tokenInfo(tokenAddress);
      const tokenContract = new ethers.Contract(tokenAddress, tokenAbi, provider);
      const actualSupply = await tokenContract.totalSupply();
      
      const maxSupply = ethers.BigNumber.from(tokenInfo.maxSupply);
      
      setToken({
        name: tokenInfo.name,
        symbol: tokenInfo.symbol,
        imageUrl: tokenInfo.imageUrl,
        tokenAddress: tokenInfo.tokenAddress,
        totalSupply: actualSupply,
        maxSupply: maxSupply,
        maxTxPercent: tokenInfo.maxTxPercent.toString(),
        exists: tokenInfo.exists
      });

      if (account) {
        const balance = await factoryContract.getTokenBalance(tokenAddress, account);
        setUserBalance(balance);
      }

      setLoading(false);
    } catch (error) {
      console.error('Error fetching token details:', error);
      setLoading(false);
    }
  }, [tokenAddress, account]);

  useEffect(() => {
    fetchTokenDetails();
  }, [fetchTokenDetails]);

  const fetchQuote = async (inputAmount, isSell) => {
    try {
      const tokenUnits = Math.floor(parseFloat(inputAmount));
      if (tokenUnits <= 0) {
        setQuoteData({
          totalCost: ethers.BigNumber.from(0),
          isStale: false,
          isLoading: false
        });
        return;
      }

      setQuoteData(prev => ({
        ...prev,
        isStale: true,
        isLoading: true
      }));

      const factoryContract = new ethers.Contract(FACTORY_ADDRESS, abi, provider);
      const [totalCost] = await factoryContract.getQuote(tokenAddress, tokenUnits, isSell);
      
      setQuoteData({
        totalCost,
        isStale: false,
        isLoading: false
      });
    } catch (error) {
      console.error('Error fetching quote:', error);
      setQuoteData(prev => ({
        ...prev,
        isStale: false,
        isLoading: false
      }));
    }
  };

  const handleBuy = async () => {
    if (!signer || !account) {
      alert('Please connect your wallet first');
      return;
    }

    if (!amount || parseFloat(amount) <= 0) {
      alert('Please enter a valid amount');
      return;
    }

    try {
      setTransactionPending(true);
      const factoryContract = new ethers.Contract(
        FACTORY_ADDRESS,
        abi,
        signer
      );

      const tokenUnits = Math.floor(parseFloat(amount));
      console.log('Buying whole tokens:', tokenUnits);
      
      const [totalCost] = await factoryContract.getQuote(tokenAddress, tokenUnits, false);
      console.log('Total cost raw:', totalCost.toString());
      console.log('Total cost in TARA:', ethers.utils.formatEther(totalCost));

      const costWithSlippage = totalCost.mul(102).div(100);
      console.log('Cost with slippage:', ethers.utils.formatEther(costWithSlippage), 'TARA');

      const tx = await factoryContract.buyTokens(
        tokenAddress,
        tokenUnits,
        { 
          value: costWithSlippage,
          gasLimit: 500000
        }
      );

      console.log('Transaction sent:', tx.hash);
      await tx.wait();
      console.log('Transaction confirmed');
      
      await fetchTokenDetails();
      setAmount('');
    } catch (error) {
      console.error('Buy error:', error);
      alert(`Error buying tokens: ${error.message}`);
    } finally {
      setTransactionPending(false);
    }
  };

  const handleSell = async () => {
    if (!signer || !account) {
      alert('Please connect your wallet first');
      return;
    }

    if (!amount || parseFloat(amount) <= 0) {
      alert('Please enter a valid amount');
      return;
    }

    try {
      setTransactionPending(true);
      
      const tokenUnits = Math.floor(parseFloat(amount));
      console.log('Selling whole tokens:', tokenUnits);

      const factoryContract = new ethers.Contract(
        FACTORY_ADDRESS,
        abi,
        signer
      );

      // Get quote with isSell = true for selling
      const [totalReturn] = await factoryContract.getQuote(tokenAddress, tokenUnits, true);
      console.log('Expected return raw:', totalReturn.toString());
      console.log('Expected return in TARA:', ethers.utils.formatEther(totalReturn));

      const returnWithSlippage = totalReturn.mul(98).div(100); // 5% slippage protection
      console.log('Return with slippage:', ethers.utils.formatEther(returnWithSlippage), 'TARA');

      // Handle token approval
      const tokenContract = new ethers.Contract(
        tokenAddress,
        tokenAbi,
        signer
      );

      const amountInWei = ethers.utils.parseEther(amount);
      const allowance = await tokenContract.allowance(account, FACTORY_ADDRESS);
      if (allowance.lt(amountInWei)) {
        console.log('Approving tokens...');
        const approveTx = await tokenContract.approve(FACTORY_ADDRESS, amountInWei);
        await approveTx.wait();
        console.log('Approval confirmed');
      }

      const tx = await factoryContract.sellTokens(
        tokenAddress, 
        tokenUnits,
        {
          gasLimit: 500000
        }
      );

      console.log('Transaction sent:', tx.hash);
      await tx.wait();
      console.log('Transaction confirmed');
      
      await fetchTokenDetails();
      setAmount('');
    } catch (error) {
      console.error('Error selling tokens:', error);
      alert(`Error selling tokens: ${error.message}`);
    } finally {
      setTransactionPending(false);
    }
  };

  const formatMaxTxPercent = (maxTxPercent) => {
    return maxTxPercent || '0';
  };

  console.log('Raw maxTxPercent:', token?.maxTxPercent);

  if (loading) {
    return (
      <Layout>
        <div style={{ width: '300px', height: '300px' }}>
          <Lottie 
            animationData={tokenloadingAnimation} 
            loop={true}
            style={{ width: '100%', height: '100%' }}
          />
        </div>
      </Layout>
    );
  }

  if (!token) {
    return (
      <Layout>
        <div>Token not found</div>
      </Layout>
    );
  }

  return (
    <Layout>
      <div style={{ 
        width: '100%',
        maxWidth: '800px',
        margin: '0 auto',
        padding: '10px'
      }}>
        <div style={{ 
          padding: '16px',
          borderRadius: '8px',
          border: '2px solid rgba(147, 51, 234, 0.3)',
          backgroundColor: 'rgba(147, 51, 234, 0.15)',
          marginBottom: '20px'
        }}>
          {/* Image and Info container */}
          <div style={{ 
            display: 'flex', 
            flexDirection: window.innerWidth <= 768 ? 'column' : 'row',
            gap: '20px', 
            marginBottom: '20px',
            alignItems: 'center'
          }}>
            {/* Token Image */}
            {token.imageUrl && (
              <div style={{ 
                width: window.innerWidth <= 768 ? '100%' : '150px',
                maxWidth: '150px',
                height: '150px',
                borderRadius: '8px',
                overflow: 'hidden',
                border: '2px solid rgba(147, 51, 234, 0.3)'
              }}>
                <img 
                  src={getImageUrl(token.imageUrl)} 
                  alt={token.name}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover'
                  }}
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.style.display = 'none';
                  }}
                />
              </div>
            )}

            {/* Token Information */}
            <div style={{ 
              flex: 1,
              width: window.innerWidth <= 768 ? '100%' : 'auto',
              padding: window.innerWidth <= 768 ? '16px' : '24px',
              borderRadius: '8px',
              border: '2px solid rgba(147, 51, 234, 0.3)',
              backgroundColor: 'rgba(147, 51, 234, 0.2)'
            }}>
              <h2 style={{ 
                fontSize: '24px', 
                marginBottom: '16px', 
                color: 'white',
                textShadow: '-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000'
              }}>
                {token.name} ({token.symbol})
              </h2>
              <p style={{ 
                marginBottom: '12px', 
                color: 'white',
                textShadow: '-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000'
              }}>
                Token Address: {token.tokenAddress}
              </p>
              <div style={{ 
                marginBottom: '20px', 
                color: 'white',
                textShadow: '-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000'
              }}>
                <p>Current Minted Supply: {ethers.utils.formatUnits(token.totalSupply, 18)} {token.symbol}</p>
                <p>Maximum Supply: {ethers.utils.formatUnits(token.maxSupply, 18)} {token.symbol}</p>
                <p style={{ 
                  fontSize: '14px',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}>
                  Max TX: {token ? formatMaxTxPercent(token.maxTxPercent) : '0'}%
                </p>
                <p>Your Balance: {ethers.utils.formatUnits(userBalance, 18)} {token.symbol}</p>
              </div>
            </div>
          </div>

          {/* Add TokenChart here, before the Total Cost section */}
          <div style={{ 
            padding: '24px',
            borderRadius: '8px',
            border: '2px solid rgba(147, 51, 234, 0.3)',
            backgroundColor: 'rgba(60, 60, 60, 0.2)',
            marginBottom: '20px'
          }}>
            <TokenChart tokenAddress={tokenAddress} />
          </div>

          {/* Total Cost */}
          <div style={{ 
            padding: '24px',
            borderRadius: '8px',
            border: '2px solid rgba(147, 51, 234, 0.3)',
            backgroundColor: 'rgba(147, 51, 234, 0.2)',
            marginBottom: '20px',
            transition: 'opacity 0.2s'
          }}>
            <div style={{ 
              display: 'flex', 
              alignItems: 'center', 
              gap: '12px',
              opacity: quoteData.isStale ? 0.5 : 1,
              color: 'white',
              textShadow: '-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000'
            }}>
              <h3>Total Cost:</h3>
              <span style={{ fontSize: '1.2em', fontWeight: 'bold' }}>
                {quoteData.isLoading ? (
                  <span>Loading...</span>
                ) : (
                  `${ethers.utils.formatEther(quoteData.totalCost)} TARA`
                )}
              </span>
            </div>
          </div>

          {/* Buy/Sell Buttons */}
          <div style={{ 
            padding: '24px',
            borderRadius: '8px',
            border: '2px solid rgba(147, 51, 234, 0.3)',
            backgroundColor: 'rgba(147, 51, 234, 0.2)'
          }}>
            <input
              type="number"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              onBlur={() => {
                if (amount) {
                  fetchQuote(amount, false);
                } else {
                  setQuoteData({
                    totalCost: ethers.BigNumber.from(0),
                    isStale: false,
                    isLoading: false
                  });
                }
              }}
              placeholder="Enter amount"
              style={{
                width: '100%',
                padding: '12px',
                marginBottom: '16px',
                backgroundColor: '#1a1a1a',
                border: '1px solid #2d2d2d',
                borderRadius: '4px',
                color: 'white'
              }}
            />
            
            <div style={{ display: 'flex', gap: '12px' }}>
              <button
                onClick={handleBuy}
                disabled={transactionPending}
                style={{
                  flex: 1,
                  padding: '12px',
                  backgroundColor: '#064e3b',
                  border: 'none',
                  borderRadius: '4px',
                  color: 'white',
                  cursor: transactionPending ? 'not-allowed' : 'pointer',
                  opacity: transactionPending ? 0.7 : 1
                }}
              >
                {transactionPending ? 'Processing...' : 'Buy'}
              </button>
              <button
                onClick={handleSell}
                disabled={transactionPending}
                style={{
                  flex: 1,
                  padding: '12px',
                  backgroundColor: '#7f1d1d',
                  border: 'none',
                  borderRadius: '4px',
                  color: 'white',
                  cursor: transactionPending ? 'not-allowed' : 'pointer',
                  opacity: transactionPending ? 0.7 : 1
                }}
              >
                {transactionPending ? 'Processing...' : 'Sell'}
              </button>
            </div>
          </div>
        </div>
      </div>
      
      {/* Comments section */}
      <Comments tokenAddress={tokenAddress} />
    </Layout>
  );
};

export default TokenPage; 