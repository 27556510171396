import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useWallet } from '../context/WalletContext';
import logo from '../assets/logo.png';

const Layout = ({ children }) => {
  const navigate = useNavigate();
  const { account, connectMetaMask, connectWalletConnect, disconnect } = useWallet();

  return (
    <div className="app">
      <nav className="navbar" style={{
        display: 'flex',
        justifyContent: 'center',
        padding: '10px',
        flexWrap: 'wrap',
        gap: '10px'
      }}>
        <div style={{ 
          display: 'flex',
          gap: '10px',
          alignItems: 'center',
          flexWrap: 'wrap',
          justifyContent: 'center'
        }}>
          <button className="nav-button" onClick={() => navigate('/')}>
            Home
          </button>
          <button className="nav-button" onClick={() => navigate('/token-create')}>
            Create Token
          </button>
          {!account ? (
            <>
              <button className="nav-button" onClick={connectMetaMask}>
                🦊 MetaMask
              </button>
              <button className="nav-button" onClick={connectWalletConnect}>
                📱 WalletConnect
              </button>
            </>
          ) : (
            <button className="nav-button" onClick={disconnect}>
              {`${account.slice(0, 6)}...${account.slice(-4)}`}
            </button>
          )}
        </div>
      </nav>

      <div style={{ 
        textAlign: 'center', 
        marginTop: '10px',
        marginBottom: '0px',
        padding: '0 20px'
      }}>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '8px',
          flexWrap: 'wrap'
        }}>
          <img 
            src={logo} 
            alt="Logo" 
            style={{ 
              height: 'clamp(100px, 20vw, 150px)', 
              width: 'auto',
              objectFit: 'contain'
            }} 
          />
          <h1 style={{ 
            fontSize: 'clamp(32px, 8vw, 48px)',
            fontWeight: 'bold',
            color: 'white',
            margin: 0,
            padding: 0,
            lineHeight: 1
          }}>
            Pumpfair
          </h1>
        </div>
        <p style={{ 
          fontSize: 'clamp(16px, 4vw, 20px)',
          color: 'white',
          opacity: 0.9,
          maxWidth: '600px',
          margin: '20px auto',
          padding: '0 10px'
        }}>
          Create and trade tokens fairly, with anti-abuse mechanics built-in
        </p>
      </div>

      <div className="content-area">
        <div className="card-list">
          {children}
        </div>
      </div>
    </div>
  );
};

export default Layout;
