import { ethers } from 'ethers';
import { addDoc, collection } from 'firebase/firestore';
import { db } from '../config/firebase';

export const listenToTradeEvents = async (contractAddress, abi, provider) => {
  const contract = new ethers.Contract(contractAddress, abi, provider);

  const processTradeEvent = async (trade, timeframe) => {
    try {
      await addDoc(collection(db, 'candles'), {
        tokenAddress: trade.tokenAddress,
        timeframe: timeframe,
        timestamp: new Date(),
        open: trade.price,
        high: trade.price,
        low: trade.price,
        close: trade.price,
        volume: trade.amount,
        updatedAt: new Date()
      });
    } catch (error) {
      console.error('Error processing trade event:', error);
    }
  };

  contract.on('TokensPurchased', async (token, buyer, amount, cost, event) => {
    const trade = {
      tokenAddress: token,
      price: parseFloat(ethers.utils.formatEther(cost)) / parseFloat(ethers.utils.formatEther(amount)),
      amount: parseFloat(ethers.utils.formatEther(amount)),
      timestamp: new Date(),
      type: 'buy'
    };
    await processTradeEvent(trade, '5m');
  });

  contract.on('TokensSold', async (token, seller, amount, received, event) => {
    const trade = {
      tokenAddress: token,
      price: parseFloat(ethers.utils.formatEther(received)) / parseFloat(ethers.utils.formatEther(amount)),
      amount: parseFloat(ethers.utils.formatEther(amount)),
      timestamp: new Date(),
      type: 'sell'
    };
    await processTradeEvent(trade, '5m');
  });
}; 