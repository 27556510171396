import React from 'react';
import { useNavigate } from 'react-router-dom';

const Card = ({ token }) => {
  const navigate = useNavigate();

  const getImageUrl = (ipfsUrl) => {
    if (!ipfsUrl) return null;
    return ipfsUrl.replace('ipfs://', 'https://gateway.pinata.cloud/ipfs/');
  };

  const maxTxPercentDisplay = (maxTxPercent) => {
    return maxTxPercent || '0';
  };

  const textStyle = {
    color: 'white',
    textShadow: `
      -1px -1px 0 #000,  
       1px -1px 0 #000,
      -1px  1px 0 #000,
       1px  1px 0 #000
    `,
    fontWeight: 'bold'
  };

  const formatAddress = (address) => {
    return `${address.slice(0, 6)}...${address.slice(-4)}`;
  };

  return (
    <div 
      className="animated-border fade-in-up" 
      style={{
        padding: '12px',
        margin: '0',
        width: '220px',
        cursor: 'pointer',
        perspective: '1000px'
      }}
      onClick={() => navigate(`/token/${token.tokenAddress}`)}
    >
      <div className="card-inner">
        <div className="card-front">
          {token.imageUrl && (
            <div style={{ 
              width: '100%',
              height: '200px',
              overflow: 'hidden',
              marginBottom: '0px',
              borderRadius: '4px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
              <img 
                src={getImageUrl(token.imageUrl)} 
                alt={token.name}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'scale-down',
                  padding: '4px'
                }}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.style.display = 'none';
                }}
              />
            </div>
          )}
          <div style={{ padding: '0 4px' }}>
            <h3 style={{ 
              ...textStyle,
              fontSize: 'clamp(14px, 4vw, 16px)', 
              marginBottom: '8px',
              lineHeight: '1.2',
              display: '-webkit-box',
              WebkitLineClamp: '2',
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              wordBreak: 'break-word'
            }}>
              {token.name} ({token.symbol})
            </h3>
            <p style={{ 
              ...textStyle,
              fontSize: 'clamp(11px, 3vw, 13px)', 
              marginBottom: '4px'
            }}>
              Token: {formatAddress(token.tokenAddress)}
            </p>
            <p style={{ 
              ...textStyle,
              fontSize: 'clamp(12px, 3.5vw, 14px)',
              marginBottom: '6px'
            }}>
              Supply: {parseFloat(token.totalSupply).toLocaleString()} {token.symbol}
            </p>
            <p style={{ 
              ...textStyle,
              fontSize: 'clamp(12px, 3.5vw, 14px)'
            }}>
              Max TX: {maxTxPercentDisplay(token.maxTxPercent)}%
            </p>
          </div>
        </div>
        
        <div className="card-back">
          <div style={{ padding: '0 4px' }}>
            <h3 style={{ 
              ...textStyle,
              fontSize: 'clamp(14px, 4vw, 16px)', 
              marginBottom: '12px'
            }}>
              Token Details
            </h3>
            <p style={{ 
              ...textStyle,
              fontSize: 'clamp(11px, 3vw, 13px)', 
              marginBottom: '4px'
            }}>
              Contract: {formatAddress(token.tokenAddress)}
            </p>
            <p style={{ 
              ...textStyle,
              fontSize: 'clamp(12px, 3.5vw, 14px)', 
              marginBottom: '8px'
            }}>
              Total Supply: {parseFloat(token.totalSupply).toLocaleString()}
            </p>
            <p style={{ 
              ...textStyle,
              fontSize: 'clamp(12px, 3.5vw, 14px)'
            }}>
              Click to view more details
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;