import React, { createContext, useState, useContext } from 'react';
import { ethers } from 'ethers';
import { EthereumProvider } from '@walletconnect/ethereum-provider';

const WalletContext = createContext();

export const WalletProvider = ({ children }) => {
  const [account, setAccount] = useState(null);
  const [provider, setProvider] = useState(null);
  const [signer, setSigner] = useState(null);
  const [wcProvider, setWcProvider] = useState(null);

  const connectMetaMask = async () => {
    try {
      if (window.ethereum) {
        const accounts = await window.ethereum.request({ 
          method: 'eth_requestAccounts' 
        });
        
        const account = accounts[0];
        setAccount(account);

        const provider = new ethers.providers.Web3Provider(window.ethereum);
        setProvider(provider);
        
        const signer = provider.getSigner();
        setSigner(signer);

        // Switch to Taraxa testnet
        try {
          await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: '0x34A' }],
          });
        } catch (switchError) {
          if (switchError.code === 4902) {
            await window.ethereum.request({
              method: 'wallet_addEthereumChain',
              params: [{
                chainId: '0x34A',
                chainName: 'Taraxa Testnet',
                nativeCurrency: {
                  name: 'TARA',
                  symbol: 'TARA',
                  decimals: 18
                },
                rpcUrls: ['https://rpc.testnet.taraxa.io'],
                blockExplorerUrls: ['https://explorer.testnet.taraxa.io']
              }]
            });
          }
        }
      } else {
        alert('Please install MetaMask!');
      }
    } catch (error) {
      console.error('Error connecting MetaMask:', error);
    }
  };

  const connectWalletConnect = async () => {
    try {
      const wcProvider = await EthereumProvider.init({
        projectId: '905643b69e4263f40b0e0fc2b06ef0c3', // Get this from https://cloud.walletconnect.com/
        chains: [842], // Taraxa testnet
        showQrModal: true,
        rpcMap: {
          842: 'https://rpc.testnet.taraxa.io'
        },
        metadata: {
          name: 'Pumpfair',
          description: 'A fairer token launch platform',
          url: window.location.host,
          icons: ['https://your-app-icon.png']
        }
      });

      await wcProvider.enable();
      setWcProvider(wcProvider);

      const ethersProvider = new ethers.providers.Web3Provider(wcProvider);
      setProvider(ethersProvider);

      const signer = ethersProvider.getSigner();
      setSigner(signer);

      const accounts = await ethersProvider.listAccounts();
      setAccount(accounts[0]);

      // Subscribe to events
      wcProvider.on('accountsChanged', (accounts) => {
        setAccount(accounts[0]);
      });

      wcProvider.on('chainChanged', (chainId) => {
        window.location.reload();
      });

      wcProvider.on('disconnect', () => {
        disconnect();
      });

    } catch (error) {
      console.error('Error connecting WalletConnect:', error);
    }
  };

  const disconnect = async () => {
    if (wcProvider) {
      await wcProvider.disconnect();
      setWcProvider(null);
    }
    setAccount(null);
    setProvider(null);
    setSigner(null);
  };

  return (
    <WalletContext.Provider value={{ 
      account, 
      provider,
      signer, 
      connectMetaMask,
      connectWalletConnect,
      disconnect
    }}>
      {children}
    </WalletContext.Provider>
  );
};

export const useWallet = () => useContext(WalletContext); 