import React, { useState, useEffect } from 'react';
import { collection, addDoc, query, where, orderBy, onSnapshot, serverTimestamp } from 'firebase/firestore';
import { formatDistanceToNow } from 'date-fns';
import { db } from '../config/firebase';
import { useWallet } from '../context/WalletContext';

// Function to generate a consistent color for an address
const getAddressColor = (address) => {
  // Use the first 6 characters of the address as a seed
  const seed = parseInt(address.slice(2, 8), 16);
  
  // Generate HSL values with good spread
  const hue = seed % 360;  // 0-359 degrees
  const saturation = 70;   // Fixed saturation
  const lightness = 85;    // Fixed lightness
  const alpha = 0.3;       // Fixed transparency

  return `hsla(${hue}, ${saturation}%, ${lightness}%, ${alpha})`;
};

const Comments = ({ tokenAddress }) => {
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { account } = useWallet();

  useEffect(() => {
    if (!tokenAddress) {
      console.log('No token address provided');
      return;
    }

    console.log('Setting up comments listener for:', tokenAddress);

    const q = query(
      collection(db, 'comments'),
      where('tokenAddress', '==', tokenAddress),
      orderBy('timestamp', 'asc')
    );

    const unsubscribe = onSnapshot(q, 
      (snapshot) => {
        console.log('Received comments update:', snapshot.size, 'comments');
        const newComments = snapshot.docs.map(doc => {
          const data = doc.data();
          return {
            id: doc.id,
            ...data,
            timestamp: data.timestamp?.toDate?.() || null
          };
        });
        setComments(newComments);
      },
      (error) => {
        console.error('Error fetching comments:', error);
      }
    );

    return () => unsubscribe();
  }, [tokenAddress]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!newComment.trim()) return;
    setIsLoading(true);

    try {
      await addDoc(collection(db, 'comments'), {
        authorAddress: account,
        content: newComment.trim(),
        timestamp: serverTimestamp(),
        tokenAddress
      });
      setNewComment('');
    } catch (error) {
      console.error('Error adding comment: ', error);
    } finally {
      setIsLoading(false);
    }
  };

  const formatTimestamp = (timestamp) => {
    if (!timestamp || !(timestamp instanceof Date)) return '';
    try {
      return formatDistanceToNow(timestamp, { addSuffix: true });
    } catch (error) {
      console.error('Error formatting timestamp:', error);
      return '';
    }
  };

  return (
    <div className="comments-section" style={{
      padding: '20px',
      backgroundColor: 'rgba(147, 51, 234, 0.1)',
      borderRadius: '8px',
      marginTop: '20px'
    }}>
      <h3 style={{ marginBottom: '20px', color: '#4a5568' }}>Discussion</h3>
      
      {/* Comment Form */}
      <form onSubmit={handleSubmit} style={{ marginBottom: '20px' }}>
        <textarea
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
          placeholder={account ? "Join the discussion..." : "Connect wallet to comment"}
          disabled={!account || isLoading}
          style={{
            width: '100%',
            minHeight: '80px',
            padding: '12px',
            borderRadius: '8px',
            border: '1px solid #e2e8f0',
            marginBottom: '10px',
            backgroundColor: account ? '#fff' : '#f7fafc'
          }}
        />
        <button
          type="submit"
          disabled={!account || isLoading}
          style={{
            backgroundColor: '#064e3b',
            color: 'white',
            padding: '8px 16px',
            borderRadius: '4px',
            border: 'none',
            cursor: account ? 'pointer' : 'not-allowed',
            opacity: account ? 1 : 0.7
          }}
        >
          {isLoading ? 'Posting...' : 'Post Comment'}
        </button>
      </form>

      {/* Comments List */}
      <div className="comments-list">
        {comments.map((comment) => (
          <div
            key={comment.id}
            style={{
              padding: '16px',
              borderRadius: '8px',
              backgroundColor: 'white',
              marginBottom: '12px',
              boxShadow: '0 1px 3px rgba(0,0,0,0.1)'
            }}
          >
            <div style={{ 
              display: 'flex', 
              justifyContent: 'space-between', 
              marginBottom: '8px',
              color: '#718096'
            }}>
              <span style={{ 
                fontWeight: 'bold',
                padding: '2px 8px',
                borderRadius: '12px',
                backgroundColor: getAddressColor(comment.authorAddress),
              }}>
                {`${comment.authorAddress.slice(0, 6)}...${comment.authorAddress.slice(-4)}`}
              </span>
              <span style={{ fontSize: '0.875rem' }}>
                {formatTimestamp(comment.timestamp)}
              </span>
            </div>
            <p style={{ color: '#2d3748' }}>{comment.content}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Comments;