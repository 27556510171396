import './App.css';
import Main from './components/Main';

import { ThemeProvider, createTheme } from '@mui/material';
import { WalletProvider } from './context/WalletContext';
import { useEffect } from 'react';
import { ethers } from 'ethers';
import { listenToTradeEvents } from './services/eventListener';
import { abi } from './components/abi';
import { provider } from './utils/provider';

const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#86efac',
    },
  },
});

const App = () => {
  useEffect(() => {
    listenToTradeEvents(process.env.REACT_APP_CONTRACT_ADDRESS, abi, provider);
  }, []);

  return (
    <ThemeProvider theme={lightTheme}>
      <WalletProvider>
        <div className="App">
          <Main />
        </div>
      </WalletProvider>
    </ThemeProvider>
  );
};

export default App;
